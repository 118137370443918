import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, inject, input, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController } from '@ionic/angular';
import { DatetimeChangeEventDetail, DatetimeHighlight, IonDatetimeCustomEvent, IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { CameraButtonModule, ImageDialogComponent, StorageService, fieldSorter } from '@scandium-oy/ngx-scandium';
import { addDays, addHours, addMilliseconds, addMonths, addWeeks, addYears, hoursToMilliseconds, startOfToday } from 'date-fns';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { FreeLicenseDialogComponent } from 'src/app/contractors/free-license/free-license.dialog';
import { FreeSelectWorkerComponent } from 'src/app/kanban/ticket/component/select-worker/select-worker.component';
import { Checklist } from 'src/app/models/checklist.model';
import { Contractor } from 'src/app/models/contractor.model';
import { Instruction } from 'src/app/models/instruction.model';
import { Room } from 'src/app/models/room.model';
import { SiteProjectPeriod } from 'src/app/models/site-project-period.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { RoomTicket, TicketStatus } from 'src/app/models/ticket.model';
import { User } from 'src/app/models/user.model';
import { WorkMachine } from 'src/app/models/work-machine.model';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { AreasService } from 'src/app/services/areas.service';
import { ChecklistService } from 'src/app/services/checklist.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { InstructionsService } from 'src/app/services/instructions.service';
import { LicenseService, appModules } from 'src/app/services/licence.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SiteProjectPeriodsService } from 'src/app/services/site-project-period.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { StripeService } from 'src/app/services/stripe.service';
import { UsersService } from 'src/app/services/users.service';
import { WorkMachinesService } from 'src/app/services/work-machines.service';
import { WorkerTicketsService } from 'src/app/services/worker-tickets.service';
import { SelectLocationDialogComponent } from 'src/app/sites/site/components/blueprints/select-location/select-location.dialog';
import { storageKeys } from 'src/app/utility/constants';
import { getLeaf } from 'src/app/utility/kanban';
import { getUniqueGuid } from 'src/app/utility/list';
import { deepClone } from 'src/app/utility/object';
import { Roles } from 'src/app/utility/role';
import { defaultRoom } from 'src/app/utility/room';
import { defaultTask, defaultTaskOnSave, maxImages } from 'src/app/utility/ticket';
import { convertTimestamp, getDateWithTimezone, getDateWithoutTimezone, timeArray, validateDates } from 'src/app/utility/time';
import { ScheduleTicketDialogComponent } from 'src/app/working-calendar/site/dialog/schedule/schedule-ticket.dialog';
import { AttachmentsDialogComponent } from '../../attachments/attachments.dialog';
import { CarouselImage } from '../../carousel/images/carousel-image.model';
import { CarouselImagesComponent } from '../../carousel/images/carousel-images.component';
import { SelectAreaDialogComponent } from '../../select-area/select-area.dialog';
import { SelectDayComponent } from '../../select-day/select-day.component';
import { SelectLitteraDialogComponent } from '../../select-littera/select-littera.dialog';
import { SelectPeriodDialogComponent } from '../../select-project/select-period/select-period.dialog';
import { SelectSiteButtonComponent } from '../../select-site/select-site.component';
import { SpeechToTextComponent } from '../../speech-to-text/speech-to-text.component';
import { TakeVideoComponent } from '../../take-video/take-video.component';

@Component({
  standalone: true,
  selector: 'app-new-adhoc',
  templateUrl: './new-adhoc.component.html',
  styleUrls: ['./new-adhoc.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    SelectSiteButtonComponent,
    CarouselImagesComponent,
    CameraButtonModule,
    TakeVideoComponent,
    TranslateTicketPipe,
    SpeechToTextComponent,
    SelectDayComponent,
    FreeSelectWorkerComponent,
  ],
})
export class NewAdhocComponent implements OnInit {
  private areaService = inject(AreasService);
  private checklistService = inject(ChecklistService);
  private contractorsService = inject(ContractorsService);
  private formBuilder = inject(FormBuilder);
  private instructionsService = inject(InstructionsService);
  private licenseService = inject(LicenseService);
  private modalCtrl = inject(ModalController);
  private projectsService = inject(ProjectsService);
  private schedulesService = inject(SchedulesService);
  private siteProjectsService = inject(SiteProjectsService);
  private siteProjectPeriodsService = inject(SiteProjectPeriodsService);
  private storageService = inject(StorageService);
  private stripeService = inject(StripeService);
  private translate = inject(TranslateService);
  private translateTicketPipe = inject(TranslateTicketPipe);
  private usersService = inject(UsersService);
  private workerTicketsService = inject(WorkerTicketsService);
  private workMachinesService = inject(WorkMachinesService);

  private selectedContractor$ = new BehaviorSubject<Contractor>(null);
  private site$ = new BehaviorSubject<Site>(null);
  private areaRooms$ = this.site$.asObservable().pipe(
    filter((site) => site != null),
    switchMap((_site) => this.areaService.getAreaRooms(this.site$.asObservable())),
    map((rooms) => rooms?.sort(fieldSorter(['siteArea.id', 'id'])) ?? []),
    shareReplay(1),
  );

  private contractorS = toSignal(this.selectedContractor$.asObservable());
  private siteS = toSignal(this.site$.asObservable());

  additionalWork = input<boolean>(false);

  job = input<SiteProjectPeriod>(null);
  project = input<SiteProject>(null);
  room = input<Room>(null);
  site = input<Site>(null);
  sites = input<Site[]>(null);
  ticket = input<RoomTicket>(null);
  aiTask = input(false);
  setWorker = input(false);

  saved = output<RoomTicket[]>();

  readonly appModules = appModules;
  readonly defaultRoom = defaultRoom;
  readonly pickerColumns = timeArray;
  readonly maxImages = maxImages;

  freeLimit = signal(false);
  formGroup: FormGroup;
  isSitelead = signal(false);
  selectedSite = signal<Site>(null);
  selectedRooms = signal<Room[]>([]);
  noAdditionalCheck = signal(false);
  initialAdditionalWork = signal(false);
  loading = signal(false);
  hasBlueprints = signal(false);
  initialProject = signal<SiteProject>(null);
  images = signal<CarouselImage[]>([]);
  minDate = signal(getDateWithTimezone(startOfToday()));
  highlightedDates = signal<DatetimeHighlight[]>([]);
  selectedWorkers = signal<string[]>([]);
  selectedJob = signal<SiteProjectPeriod & {
    parent: SiteProject;
  }>(null);

  hasLitteras = computed(() => this.selectedSite()?.classification != null);

  canSelectWorker = computed(() => {
    const role = this.usersService.currentUserS().role;
    if (role === Roles.manager) {
      return !this.contractorS()?.siteOptions?.find((it) => it.site === this.siteS()?.guid)?.preventManagerToAssign;
    }
    return this.isSitelead() || ![Roles.worker].includes(role);
  });

  canSelectSubcontractor$ = combineLatest([this.site$, this.contractorsService.getCurrentContractor()]).pipe(
    filter(([site, contractor]) => site != null && contractor != null),
    map(([site, contractor]) => site.mainContractors?.some((it) => it.guid === contractor.guid)),
    shareReplay(1),
  );

  instructions$ = this.selectedContractor$.pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.instructionsService.getList({ contractor: contractor.guid })),
    map((items) => items.map((it) => ({ guid: it.guid, name: it.name[this.usersService.currentUserS().lang ?? 'fi'] }))),
    shareReplay(1),
  );

  limit$ = this.contractorsService.taskLimit$.pipe(
    tap((limit) => {
      if (!this.aiTask() && !limit.isAvailable) {
        setTimeout(async () => Dialog.alert({ message: this.translate.instant('contractor.freeLimit') }), 600);
        this.freeLimit.set(true);
      }
    }),
  );

  modules$ = this.licenseService.getModules().pipe(
    shareReplay(1),
  );

  isFree$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  subcontractors$ = this.site$.pipe(
    filter((site) => site != null),
    switchMap((site) => this.contractorsService.getList({ client: site.client })),
    map((contractors) => [
      ...contractors.map((it) => ({ guid: it.guid, name: it.name, siteOptions: it.siteOptions })),
      this.usersService.currentUserS().role === Roles.manager ? { guid: Roles.manager, name: this.translate.instant('contractor.managers') } : null,
      { name: this.translate.instant('contractor.addNew'), guid: null },
    ]),
    map((items) => items.filter((it) => it != null)),
    shareReplay(1),
  );

  tasks$ = combineLatest([this.selectedContractor$.asObservable(), this.site$.asObservable()]).pipe(
    filter(([contractor, site]) => contractor != null && site != null),
    switchMap(([contractor]) => combineLatest([
      contractor?.defaultTicketTemplate ? this.checklistService.get(contractor.defaultTicketTemplate) : of<Checklist>(null),
      contractor ? this.checklistService.getList({ adhocContractor: contractor.guid }) : of<Checklist[]>([]),
    ])),
    map(([list, adHocs]) => {
      const ret = list?.fields ?? [];
      const adHocFields = adHocs
        .filter((it) => it.site == null || it.site.guid === this.site$.value.guid)
        .map((it) => it.fields)
        .flat();
      ret.push(...adHocFields);
      return ret;
    }),
    map((list) => {
      const ret = new Set<string>(list);
      return Array.from(ret);
    }),
    map((list) => [this.translate.instant('kanban.newItem'), ...list]),
    tap(() => {
      const name = this.formGroup.get('name').value;
      if (name == null) {
        this.formGroup.get('name').setValue(defaultTask);
      }
    }),
    shareReplay(1),
  );

  repeatOptions = signal([
    {
      guid: 'daily',
      name: this.translate.instant('calendar.site.repeatOptions.daily'),
    },
    {
      guid: 'weekly',
      name: this.translate.instant('calendar.site.repeatOptions.weekly'),
    },
    {
      guid: 'every2Week',
      name: this.translate.instant('calendar.site.repeatOptions.every2Week'),
    },
    {
      guid: 'monthly',
      name: this.translate.instant('calendar.site.repeatOptions.monthly'),
    },
    {
      guid: 'yearly',
      name: this.translate.instant('calendar.site.repeatOptions.yearly'),
    },
  ]);

  selectedRepeatOption = signal<SubItem & { start: string; end: string; time?: string }>(null);

  rooms$ = combineLatest([this.areaRooms$]).pipe(
    map(([areaRooms]) => [...areaRooms]),
    tap((rooms) => {
      if (this.room() == null && this.selectedRooms().length === 0) {
        this.formGroup.get('room').setValue(rooms.find((r) => r.id === defaultRoom));
      }
    }),
    shareReplay(1),
  );

  role$ = this.usersService.role$.pipe(
    shareReplay(1),
  );

  users$ = combineLatest([this.site$, this.selectedContractor$]).pipe(
    filter(([site, contractor]) => site != null && contractor != null),
    switchMap(([site, contractor]) => contractor.guid === Roles.manager ?
      this.usersService.getList(false, { site: site.guid, role: Roles.manager }) :
      this.usersService.getList(false, { site: site.guid, role: Roles.worker, contractor: contractor.guid })),
    map((users) => users.map((user) => ({ guid: user.guid, name: user.displayName }))),
    map((users) => users.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  workMachines$ = combineLatest([this.selectedContractor$, this.role$]).pipe(
    filter(([contractor, role]) => contractor != null && [Roles.admin, Roles.worker].includes(role)),
    switchMap(([contractor]) => this.workMachinesService.getList({ contractor: contractor.guid })),
    shareReplay(1),
  );

  private addToAdHoc(task: string) {
    combineLatest([this.selectedContractor$.asObservable(), this.site$.asObservable()]).pipe(
      switchMap(([contractor, site]) => this.checklistService.getList({ adhocContractor: contractor.guid, site: site.guid })),
      take(1),
    ).subscribe((items) => {
      if (items?.length > 0) {
        const existing = items[0];
        if (!existing.fields.includes(task)) {
          existing.fields.push(task);
          this.checklistService.update(existing);
        }
      } else {
        const site = this.site$.getValue();
        const contractor = this.selectedContractor$.getValue();
        const checklist: Checklist = {
          adhoc: true,
          name: `adhoc ${site.name}`,
          fields: [task],
          contractor: contractor.guid,
          site: { guid: site.guid, name: site.name },
        };
        this.checklistService.save(checklist);
      }
    });
  }

  private setDates(start: Date, end: Date) {
    if (start != null && end != null) {
      const highlightedDates: DatetimeHighlight[] = [
        {
          date: getDateWithoutTimezone(start),
          backgroundColor: 'var(--ion-color-success)',
          textColor: 'var(--ion-color-success-contrast)',
        },
        {
          date: getDateWithoutTimezone(end),
          backgroundColor: 'var(--ion-color-danger)',
          textColor: 'var(--ion-color-danger-contrast)',
        },
      ];
      this.highlightedDates.set(highlightedDates);
    } else {
      this.highlightedDates.set([]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setForm(form?: any) {
    const currentUser = this.usersService.currentUserS();
    const ticket = this.ticket();
    const users = [];
    if (ticket != null) {
      users.push(...ticket.users?.map((u, index) => ({ guid: u, name: ticket.usernames[index] })) ?? []);
    }
    const initialStart = new Date();
    initialStart.setMinutes(0, 0, 0);
    const initialEnd = addHours(initialStart, 1);

    this.formGroup = this.formBuilder.group({
      guid: [getUniqueGuid()],
      name: [ticket?.plainName ?? null, Validators.required],
      start: [getDateWithTimezone(initialStart), [Validators.required, validateDates]],
      end: [getDateWithTimezone(initialEnd), [Validators.required, validateDates]],
      duration: ticket?.hours ?? 0,
      users: [currentUser.role === Roles.worker && !this.isSitelead() ? [currentUser] : users, Validators.nullValidator],
      subcontractor: [this.isSitelead() ? { guid: this.contractorsService.contractorS().guid, name: this.contractorsService.contractorS().name } : null,
      [Roles.manager, Roles.superAdmin].includes(currentUser.role) ? Validators.required : Validators.nullValidator],
      invoiceContractor: [null],
      state: [TicketStatus.created],
      endRepeat: [],
      images: [],
      videos: [],
      attachments: [[]],
      room: [this.room() ?? null, Validators.required],
      description: [ticket?.description ?? ''],
      additionalWork: [this.initialAdditionalWork()],
      instruction: [ticket?.instruction ?? null],
      date: [this.aiTask() ? ticket.deadline : null],
      workMachine: [ticket?.workMachine ?? null],
      allocate: [false],
      schedule: [null],
      location: [null],
      littera: [null],
      planningObservation: [false],
      securityObservation: [false],
    });
    if (form) {
      this.formGroup.setValue(form);
    }
    if (this.aiTask()) {
      ticket.images?.map((img) => this.onUrl(img, 'image'));
      ticket.videos?.map((video) => this.onUrl(video, 'video'));
      if (ticket.subcontractor) {
        if ([Roles.manager].includes(currentUser.role)) {
          this.onSelectContractor({ value: ticket.subcontractor }, 'subcontractor');
        } else {
          this.onSelectContractor({ value: ticket.subcontractor }, 'invoiceContractor');
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createRoomTicket(item: any, room: Room, contractor: string, deadline?: Date) {
    const hours = +(this.formGroup.value.duration ?? 0);
    item.name = item.name === defaultTask ? defaultTaskOnSave : item.name;

    const roomTicket: RoomTicket = {
      guid: item.guid,
      name: item.name,
      plainName: item.name,
      phase: 0,
      hours,
      status: TicketStatus.created,
      history: [this.workerTicketsService.createHistory(TicketStatus.created, undefined, item.images, item.videos)],
      users: item.users?.map((u) => u.guid) ?? [],
      usernames: item.users?.map((u) => u.name ?? u.displayName) ?? [],
      room,
      images: item.images,
      videos: item.videos,
      attachments: item.attachments,
      description: item.description,
      additionalWork: false, //item.additionalWork,
      additionalWorkInfo: {}, //role === Roles.manager ? { client: this.usersService.currentUserS().displayName, clientTimestamp: new Date() } : {},
      instruction: item.instruction?.guid ?? null,
      deadline: deadline ?? (item.date ? new Date(item.date) : null),
      contractor,
      subcontractor: item.invoiceContractor ? { guid: item.invoiceContractor.guid, name: item.invoiceContractor.name } : null,
      creator: this.usersService.currentUserS()?.guid ?? null,
      workMachine: item.workMachine ?? null,
      schedule: item.schedule ?? null,
      location: item.location ?? null,
      site: this.selectedSite(),
      littera: item.littera ?? null,
      planningObservation: item.planningObservation ?? false,
      securityObservation: item.securityObservation ?? false,
      projectPeriod: this.selectedJob()?.guid ?? null,
      repeatGuid: item.repeatGuid ?? null,
      repeatTime: item.repeatTime ?? false,
    };

    if (item.allocate) {
      // Allocate worker and resource
      this.schedulesService.allocateUser(item.users, this.selectedSite().guid, contractor, roomTicket);
    }

    if (this.selectedJob() != null && this.job() == null) {
      // Add ticket to project job
      this.updatePeriod(this.selectedJob(), item);
    }

    return roomTicket;
  }

  private getNewDate(id: string, current: Date) {
    switch (id) {
      case 'daily':
        return addDays(current, 1);
      case 'weekly':
        return addWeeks(current, 1);
      case 'every2Week':
        return addWeeks(current, 2);
      case 'monthly':
        return addMonths(current, 1);
      case 'yearly':
        return addYears(current, 1);
    }
  }

  private saveAdHocTicket(_role: Roles) {
    const item = this.formGroup.value;
    const contractor = (item.subcontractor?.guid ?? this.contractorsService.contractorS()?.guid) ?? null;
    const deadlines: Date[] = [];
    if (this.selectedRepeatOption() != null) {
      const id = this.selectedRepeatOption().guid;
      const start = new Date(this.selectedRepeatOption().start);
      const end = this.selectedRepeatOption().end ? new Date(this.selectedRepeatOption().end) : addMonths(start, 12);
      const time = this.selectedRepeatOption().time ? new Date(this.selectedRepeatOption().time) : null;
      let current = start;
      if (time) {
        current.setHours(time.getHours());
        current.setMinutes(time.getMinutes());
        item.repeatTime = true;
      }
      while (current <= end) {
        deadlines.push(current);
        current = this.getNewDate(id, current);
      }
    }
    const repeatGuid = deadlines.length > 0 ? getUniqueGuid(new Date().getTime()) : null;
    item.repeatGuid = repeatGuid;
    if (this.selectedRooms().length > 1) {
      if (deadlines.length > 0) {
        const roomTickets = this.selectedRooms().map((room, index) => {
          return deadlines.map((deadline, dindex) => {
            item.guid = getUniqueGuid(+`${index}${dindex}`);
            return this.createRoomTicket(item, room, contractor, deadline);
          });
        });
        this.saved.emit(roomTickets.flat());
      } else {
        const roomTickets = this.selectedRooms().map((room, index) => {
          item.guid = getUniqueGuid(index);
          return this.createRoomTicket(item, room, contractor);
        });
        this.saved.emit(roomTickets);
      }
    } else {
      if (deadlines.length > 0) {
        const roomTickets = deadlines.map((deadline, index) => {
          item.guid = getUniqueGuid(index);
          return this.createRoomTicket(item, item.room, contractor, deadline);
        });
        this.saved.emit(roomTickets);
      } else {
        const roomTicket = this.createRoomTicket(item, item.room, contractor);
        this.saved.emit([roomTicket]);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSelect(field: string, value: any) {
    this.formGroup.get(field).setValue(value);
    if (field === 'subcontractor') {
      this.selectedContractor$.next(value);
      if (!this.aiTask()) {
        this.formGroup.get('name').setValue(null);
      }
    }
  }

  private onSelectContractor(param: { value: SubItem }, field = 'subcontractor') {
    if (param.value.guid == null) {
      this.projectsService.inviteNewContractor(this.selectedSite()).then((contractor) => {
        this.onSelect(field, contractor);
      });
    } else {
      this.onSelect(field, param.value);
    }
  }

  private async updatePeriod(period: SiteProjectPeriod & {
    parent: SiteProject;
  }, ticket: RoomTicket) {
    await this.siteProjectPeriodsService.addTickets(period.guid, { guid: ticket.guid, name: ticket.name });
  }

  private setRooms(data: Room[]) {
    const firstRoom = data[0];
    const room = deepClone(firstRoom);
    this.formGroup.get('room').setValue(room);
    const rooms = data.map((r) => deepClone(r));
    this.selectedRooms.set(rooms);
  }

  private setInitialProject(site: Site) {
    if ([Roles.manager, Roles.superAdmin].includes(this.usersService.currentUserS().role)) {
      this.subcontractors$.pipe(
        take(1),
      ).subscribe((contractors) => {
        const contractor = contractors.find((it) => it.guid === this.job().contractor.guid);
        this.onSelectContractor({ value: contractor }, 'subcontractor');
      });
    }
    const period = this.job();
    if (period != null) {
      const job = Object.assign({}, period, { parent: this.initialProject() });
      this.selectedJob.set(job);
    } else {
      this.selectProject(site);
    }
    const areas = this.job().areas;
    const areas$ = areas.map((a) => this.areaService.getArea(a.guid, a.id));
    combineLatest(areas$).pipe(
      take(1),
    ).subscribe((siteAreas) => {
      const leafs = siteAreas.map((sa) => {
        return getLeaf(sa).map((leaf) => {
          leaf.guid = sa.guid;
          return leaf;
        });
      }).flat();
      const rooms = leafs.map((it) => ({ id: null, siteArea: { root: it.guid, id: it.id, name: it.name }, type: 'area' } as Room));
      if (rooms.length > 0) {
        this.setRooms(rooms);
      }
    });
  }

  ngOnInit() {
    const newAdhocItem: { form: FormGroup; site: Site; additionalWork: boolean; contractor: Contractor } = this.storageService.getItem(storageKeys.newAdhoc);
    if (newAdhocItem) {
      this.initialAdditionalWork.set(newAdhocItem.additionalWork);
      if (newAdhocItem.site) {
        this.onSite(newAdhocItem.site);
      }
      this.selectedContractor$.next(newAdhocItem.contractor);
      this.setForm(newAdhocItem.form);

      if (this.setWorker()) {
        this.stripeService.getSubscriptions().pipe(
          take(1),
        ).subscribe((subs) => {
          if (subs.length > 0) {
            const last = subs[subs.length - 1];
            const { guid, name } = last.metadata;
            this.onWorker({ guid, name });
          }
        });
      }
    } else {
      const currentUser = this.usersService.currentUserS();
      this.initialAdditionalWork.set(this.additionalWork() ?? currentUser.role === Roles.manager);

      if (![Roles.manager, Roles.superAdmin].includes(currentUser.role)) {
        this.selectedContractor$.next(this.contractorsService.contractorS());
      }

      const site = this.site();
      if (site) {
        this.onSite(site);
      } else {
        this.setForm();
      }
    }
  }

  setStorage() {
    const item = {
      site: this.selectedSite(),
      form: this.formGroup.getRawValue(),
      additionalWork: this.additionalWork(),
      contractor: this.selectedContractor$.getValue(),
    };
    this.storageService.setItem(storageKeys.newAdhoc, item);
  }

  onPlanningObservation(event: IonToggleCustomEvent<ToggleChangeEventDetail<boolean>>) {
    if (event.detail.checked) {
      this.formGroup.get('subcontractor').setValue({ guid: null, name: 'Ei urakoitsijaa' });
      this.formGroup.get('name').setValue('Suunnittelun ohjaus -havainto');
    } else {
      this.formGroup.get('subcontractor').setValue(null);
      this.formGroup.get('name').setValue(null);
    }
    this.formGroup.updateValueAndValidity();
  }

  onAttachments() {
    const items = this.formGroup.get('attachments').value;
    this.modalCtrl.create({ component: AttachmentsDialogComponent, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.formGroup.get('attachments').setValue(data.data ?? []);
        }
      });
    });
  }

  onDateChange(date: string) {
    this.formGroup.get('date').setValue(date);
  }

  onDuration(value: number | string) {
    if (value === null) {
      Dialog.prompt({
        title: this.translate.instant('tickets.otherTime'),
        message: this.translate.instant('tickets.otherTimeText'),
      }).then((confirmValue) => {
        if (confirmValue) {
          this.onDuration(+confirmValue.value);
        }
      });
      return;
    }
    if (value == null) {
      value = 0;
    }
    const millis = hoursToMilliseconds(+value);
    const initialStart = new Date(this.formGroup.get('start').value);
    const end = addMilliseconds(initialStart, millis);
    this.formGroup.get('start').setValue(getDateWithTimezone(initialStart));
    this.formGroup.get('end').setValue(getDateWithTimezone(end));
    this.onSelect('duration', +value);
  }

  onSchedule() {
    this.modalCtrl.create({ component: ScheduleTicketDialogComponent }).then((m) => {
      m.present();
      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.formGroup.get('schedule').setValue(data.data);
        }
      });
    });
  }

  onSite(site: Site) {
    if (site) {
      this.site$.next(site);
      this.isSitelead.set(this.usersService.isSitelead(site));
      this.noAdditionalCheck.set(!!site.noAdditionalCheck);
      if (site.noAdditionalCheck) {
        this.initialAdditionalWork.set(false);
      }
      this.hasBlueprints.set(site.blueprints?.length > 0);
      this.selectedSite.set(site);
      this.setForm();
      this.initialProject.set(this.project() ?? null);
      if (this.initialProject() != null) {
        this.setInitialProject(site);
      }
    }
  }

  selectContractor(items: SubItem[]) {
    const clearButton = this.formGroup.get('subcontractor').value != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Contractor>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('subcontractor').setValue(null);
          } else {
            const siteGuid = this.site$.getValue().guid;
            if (data.data.sites != null && !data.data.sites.includes(siteGuid)) {
              const contractor = data.data;
              this.contractorsService.updateSite(contractor.guid, siteGuid)
                .pipe(take(1))
                .subscribe(() => this.onSelectContractor({ value: data.data }, 'subcontractor'));
              return;
            }
            this.onSelectContractor({ value: data.data }, 'subcontractor');
          }
        }
      });
    });
  }

  selectProject(site: Site) {
    const contractor = this.formGroup.get('subcontractor')?.value?.guid ?? this.contractorsService.contractorS()?.guid;
    this.siteProjectsService.getList({ site: site.guid, contractor }).pipe(
      take(1),
    ).subscribe((items) => {
      const clearButton = this.selectedJob() != null;
      this.modalCtrl.create({
        component: SelectPeriodDialogComponent,
        componentProps: { items: this.initialProject() ? [this.initialProject()] : items, clearButton, site: this.selectedSite(), contractor: this.selectedContractor$.getValue() },
      }).then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.clear) {
              this.selectedJob.set(null);
              this.setDates(null, null);
            } else {
              this.selectedJob.set(data.data);
              const start = convertTimestamp(this.selectedJob().start);
              const end = convertTimestamp(this.selectedJob().end);
              this.setDates(start, end);
            }
          }
        });
      });
    });
  }

  selectRepeatOption() {
    const clearButton = this.selectedRepeatOption() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Contractor>, componentProps: { items: this.repeatOptions(), clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedRepeatOption.set(null);
          } else {
            data.data.start = getDateWithTimezone(startOfToday());
            this.selectedRepeatOption.set(data.data);
          }
        }
      });
    });
  }

  onRepeatTimeChange(event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) {
    this.selectedRepeatOption.update((val) => {
      val.time = event.detail.value as string;
      return val;
    });
  }

  onRepeatStartChange(value: string) {
    this.selectedRepeatOption.update((val) => {
      val.start = value;
      return val;
    });
  }

  onRepeatEndChange(value: string) {
    this.selectedRepeatOption.update((val) => {
      val.end = value;
      return val;
    });
  }

  selectRoom(site: Site) {
    const areas = this.selectedRooms() ?? [];
    this.modalCtrl.create({
      component: SelectAreaDialogComponent,
      componentProps: { site: site.guid, multipleRooms: this.job() == null, multiple: this.job() == null, areas },
    }).then((m) => {
      m.present();

      m.onDidDismiss<Room | Room[]>().then((data) => {
        if (data.data) {
          if (Array.isArray(data.data)) {
            if (data.data?.length > 0) {
              this.setRooms(data.data);
            }
          } else {
            this.selectedRooms.set([]);
            const room = deepClone(data.data);
            this.formGroup.get('room').setValue(room);
          }
        }
      });
    });
  }

  selectInstructions(items: SubItem[]) {
    const clearButton = this.formGroup.get('instruction').value;
    this.modalCtrl.create({ component: SelectDialogComponent<Instruction>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('instruction').setValue(null);
          } else {
            this.formGroup.get('instruction').setValue(data.data);
          }
        }
      });
    });
  }

  selectLocation(site: Site) {
    this.modalCtrl.create({ component: SelectLocationDialogComponent, componentProps: { site }, cssClass: ['modal-fullscreen'] }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.formGroup.get('location').setValue(data.data);
        }
      });
    });
  }

  selectSubContractor(items: SubItem[]) {
    const clearButton = this.formGroup.get('invoiceContractor').value != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Contractor>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('invoiceContractor').setValue(null);
          } else {
            const siteGuid = this.site$.getValue().guid;
            if (data.data.sites != null && !data.data.sites.includes(siteGuid)) {
              const contractor = data.data;
              this.contractorsService.updateSite(contractor.guid, siteGuid)
                .pipe(take(1))
                .subscribe(() => this.onSelectContractor({ value: data.data }, 'invoiceContractor'));
              return;
            }
            this.onSelectContractor({ value: data.data }, 'invoiceContractor');
          }
        }
      });
    });
  }

  selectWorker(items: SubItem[]) {
    const clearButton = this.formGroup.get('users').value.length > 0;
    this.modalCtrl.create({ component: SelectDialogComponent<User>, componentProps: { items, clearButton, multiple: true } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('users').setValue([]);
          } else {
            this.formGroup.get('users').setValue(data.data);
          }
        }
      });
    });
  }

  selectLittera() {
    const classification = this.selectedSite().classification;
    const clearButton = this.formGroup.get('littera').value != null;
    this.modalCtrl.create({ component: SelectLitteraDialogComponent, componentProps: { clearButton, classification } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('littera').setValue(null);
          } else {
            this.formGroup.get('littera').setValue(data.data);
          }
        }
      });
    });
  }

  onWorker(item: { guid: string; name: string }) {
    this.formGroup.get('users').setValue([item]);
    this.selectedWorkers.update((val) => [...val, item.name]);
  }

  onImage(item: CarouselImage) {
    this.modalCtrl.create({
      component: ImageDialogComponent,
      componentProps: { image: item.url, images: this.formGroup.get('images').value },
      cssClass: ['modal-fullscreen', 'transparent-modal'],
    }).then((modal) => {
      modal.present();
    });
  }

  onMic(text: string) {
    const prevText = this.formGroup.get('description').value as string;
    this.formGroup.get('description').setValue(prevText ? `${prevText} ${text}` : text);
  }

  onTask(tasks: string[]) {
    const clearButton = this.formGroup.get('name').value;
    const itemsPromise = tasks.map(async (name) => ({ name: await this.translateTicketPipe.transform(name), value: name }));
    Promise.all(itemsPromise).then((items) => {
      this.modalCtrl.create({
        component: SelectDialogComponent,
        componentProps: { items, clearButton },
      }).then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            let value = data.data.value;
            if (data.data.clear) {
              this.onSelect('name', defaultTask);
              return;
            }
            if (value === this.translate.instant('kanban.newItem')) {
              Dialog.prompt({
                title: this.translate.instant('kanban.newItem'),
                message: this.translate.instant('item.name'),
              }).then((dialogValue) => {
                if (dialogValue.value) {
                  this.addToAdHoc(dialogValue.value);
                  this.formGroup.get('name').setValue(dialogValue.value);
                } else {
                  this.formGroup.get('name').setValue(defaultTask);
                }
              });
            } else {
              this.onSelect('name', value);
            }
          }
        });
      });
    });
  }

  onUrl(url: string, type: 'image' | 'video') {
    const items: string[] = this.formGroup.get(`${type}s`).value ?? [];
    items.push(url);
    this.formGroup.get(`${type}s`).setValue(items);
    const item: CarouselImage = { url, type };
    this.images.update((val) => [...val, item]);
  }

  async onWorkMachine(role: Roles, modules: Record<string, boolean>, items: WorkMachine[]) {
    if ([Roles.admin, Roles.worker, Roles.storage].includes(role) && !modules[appModules.workMachines]) {
      return this.openFree();
    }

    const clearButton = this.formGroup.get('workMachine').value;
    this.modalCtrl.create({
      component: SelectDialogComponent,
      componentProps: { items, clearButton },
    }).then((m) => {
      m.present();

      m.onDidDismiss<WorkMachine>().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('workMachine').setValue(null);
          } else {
            this.formGroup.get('workMachine').setValue({ guid: data.data.guid, name: data.data.name });
          }
        }
      });
    });
  }

  async openFree() {
    return this.modalCtrl.create({ component: FreeLicenseDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
  }

  save(role: Roles) {
    this.loading.set(true);
    this.saveAdHocTicket(role);
  }
}
