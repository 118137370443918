import { CommonModule } from '@angular/common';
import { Component, Input, computed, inject, input, output, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { StorageReference, getDownloadURL } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@scandium-oy/ngx-scandium';
import { parse } from 'date-fns';
import { combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { Contractor } from 'src/app/models/contractor.model';
import { Room } from 'src/app/models/room.model';
import { SiteArea } from 'src/app/models/site-area.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { RoomTicket, TicketStatus } from 'src/app/models/ticket.model';
import { User } from 'src/app/models/user.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { AiService, AiTask } from 'src/app/services/ai.service';
import { AreasService } from 'src/app/services/areas.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { UsersService } from 'src/app/services/users.service';
import { WorkerTicketsService, getRoomNameByRoom } from 'src/app/services/worker-tickets.service';
import { setSiteArea } from 'src/app/utility/kanban';
import { getUniqueGuid } from 'src/app/utility/list';
import { Roles } from 'src/app/utility/role';
import { defaultRoom } from 'src/app/utility/room';
import { defaultTask, defaultTaskOnSave, isRoomTicket } from 'src/app/utility/ticket';
import { CarouselItem, CarouselItemComponent } from '../../carousel/item/carousel-item.component';
import { SelectSiteButtonComponent } from '../../select-site/select-site.component';
import { SpeechToTextComponent } from '../../speech-to-text/speech-to-text.component';
import { TakeVideoComponent } from '../../take-video/take-video.component';
import { NewAdhocComponent } from '../new/new-adhoc.component';

interface ExtendedAiTask extends AiTask {
  roomObj?: Room;
  userObj?: User;
  contractorObj?: SubItem;
}

@Component({
  standalone: true,
  selector: 'app-ai-tasks',
  templateUrl: './ai.component.html',
  styleUrls: ['./ai.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    FormsModule,
    SpeechToTextComponent,
    SelectSiteButtonComponent,
    CarouselItemComponent,
    NewAdhocComponent,
    TakeVideoComponent,
  ],
})
export class AiTasksComponent {
  private aiService = inject(AiService);
  private areaService = inject(AreasService);
  private contractorsService = inject(ContractorsService);
  private loadingService = inject(LoadingService);
  private modalCtrl = inject(ModalController);
  private platform = inject(Platform);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);
  private workerTicketsService = inject(WorkerTicketsService);

  private videoUrl: string;

  siteS = signal<Site>(null);

  @Input()
  set site(value: Site) {
    this.siteS.set(value);
  }

  sites = input<Site[]>();
  saved = output<RoomTicket[]>();
  closed = output();
  hasSelectedTicket = output<boolean>();

  description = '';
  loading = signal(false);

  found = signal<CarouselItem<unknown, ExtendedAiTask | RoomTicket>[]>([]);
  step = signal(1);
  selectedTicket = signal<RoomTicket>(null);
  selectedContractor = signal<Contractor>(null);
  role = computed(() => this.usersService.currentUserS().role);
  freeLimit = signal(false);
  invalidTickets = signal(true);

  areas$ = toObservable(this.siteS).pipe(
    filter((site) => site != null),
    switchMap((site) => this.areaService.getAreas(of(site))),
    shareReplay(1),
  );

  canSelectSubcontractor$ = combineLatest([toObservable(this.siteS), this.contractorsService.getCurrentContractor()]).pipe(
    filter(([site, contractor]) => site != null && contractor != null),
    map(([site, contractor]) => site.mainContractors?.some((it) => it.guid === contractor.guid)),
    shareReplay(1),
  );

  contractors$ = toObservable(this.siteS).pipe(
    filter((site) => site != null),
    switchMap((site) => this.contractorsService.getList({ site: site.guid })),
    map((contractors) => [...contractors.map((it) => ({ guid: it.guid, name: it.name })), { name: this.translate.instant('contractor.addNew'), guid: null }]),
    shareReplay(1),
  );

  users$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => this.selectedContractor() != null || contractor != null),
    switchMap((contractor) => {
      const contractorGuid = this.selectedContractor()?.guid ?? contractor?.guid;
      return this.usersService.getList(undefined, { contractor: contractorGuid });
    }),
    shareReplay(1),
  );

  limit$ = this.contractorsService.aiLimit$.pipe(
    tap((limit) => {
      if (!limit.isAvailable) {
        setTimeout(async () => Dialog.alert({ message: this.translate.instant('contractor.freeLimit') }), 600);
        this.freeLimit.set(true);
      }
    }),
  );

  constructor() {
    this.platform.backButton.subscribeWithPriority(101, (processNextHandler) => {
      if (this.selectedTicket() != null) {
        this.selectedTicket.set(null);
      } else if (this.step() > 1) {
        this.step.update((val) => --val);
      } else {
        processNextHandler();
      }
    });
  }

  private updateTicketValidation() {
    const valid = this.found().every((it) => it.valid);
    this.invalidTickets.set(!valid);
  }

  private getRoomTicket(item: ExtendedAiTask, room: Room) {
    const hours = +item.duration;
    item.name = item.name === defaultTask ? defaultTaskOnSave : item.name;
    const currentUser = this.usersService.currentUserS();
    const contractor = [Roles.manager].includes(currentUser.role) ? item.contractorObj?.guid ?? this.selectedContractor()?.guid : this.contractorsService.contractorS().guid;
    const subcontractor = [Roles.manager].includes(currentUser.role) ? null : item.contractorObj ?? null;
    const videos = this.videoUrl ? [this.videoUrl] : [];

    const roomTicket: RoomTicket = {
      guid: getUniqueGuid(),
      name: item.name,
      plainName: item.name,
      phase: 0,
      hours,
      status: TicketStatus.created,
      history: [this.workerTicketsService.createHistory(TicketStatus.created, undefined, [], videos)],
      users: item.userObj ? [item.userObj.guid] : [],
      usernames: item.userObj ? [item.userObj.displayName] : [],
      room,
      images: [],
      videos,
      attachments: [],
      description: item.description,
      additionalWork: false,
      additionalWorkInfo: {},
      instruction: null,
      deadline: this.getDeadline(item.deadline),
      contractor,
      subcontractor,
      creator: this.usersService.currentUserS()?.guid ?? null,
      workMachine: null,
      schedule: null,
      location: null,
      site: this.siteS(),
    };
    return roomTicket;
  }

  private getCarouselItems(roomTickets: RoomTicket[], parent: ExtendedAiTask | RoomTicket) {
    return roomTickets.map((it) => ({
      guid: it.guid,
      name: it.name,
      text: it.description,
      image: it.images?.length > 0 ? it.images[0] : null,
      preview$: this.areaService.getPreview(it),
      site: it.site.name,
      info: this.workerTicketsService.getRoomInfo(it),
      hours: it.hours,
      date: it.deadline,
      parent,
      item: it,
      valid: true,
    }) as CarouselItem<ExtendedAiTask | RoomTicket, RoomTicket>);
  }

  private getRoom(areas: SiteArea[], item: ExtendedAiTask) {
    let parentArea: SiteArea;
    let root: SiteArea;
    let room: Room = { id: '', type: 'area' };
    if (item.area) {
      const foundAreas = areas.map((area) => (
        { parent: area, area: this.areaService.findArea(item.area, area) })).filter((it) => it.area != null);
      if (foundAreas.length > 0) {
        parentArea = foundAreas[0].area;
        root = foundAreas[0].parent;
        room = setSiteArea(room, root, parentArea);
      }
    }
    if (item.room) {
      if (parentArea) {
        room = this.areaService.findRoom(item.room, parentArea);
        if (room) {
          room = setSiteArea(room, root, parentArea);
        }
      }
    }
    if (room.siteArea == null) {
      const rooms = this.areaService.getRoomsFromAreas(areas);
      room = rooms.find((r) => r.id === defaultRoom);
    }
    return room;
  }

  getDeadline(deadline: string | Date) {
    if (deadline) {
      if (typeof deadline === 'string') {
        return parse(deadline, 'dd.MM.yyyy', new Date());
      } else {
        return deadline;
      }
    }
    return null;
  }

  start() {
    this.step.update((val) => ++val);
  }

  deleteItem(index: number) {
    const items = this.found();
    items.splice(index, 1);
    this.found.set(items);
    if (items.length === 0) {
      this.step.update((val) => --val);
    }
  }

  onItem(item: CarouselItem<unknown>, contractors: SubItem[]) {
    const currentUser = this.usersService.currentUserS();
    const parent = item.item as RoomTicket | ExtendedAiTask;
    const room = (isRoomTicket(parent) ? parent.room : parent.roomObj) ?? null;
    const users = (isRoomTicket(parent) ? parent.users : parent.userObj ? [parent.userObj?.guid] : []) ?? [];
    const usernames = (isRoomTicket(parent) ? parent.usernames : parent.userObj ? [parent.userObj?.displayName] : []) ?? [];
    let subcontractor = !isRoomTicket(parent) && ![Roles.manager].includes(currentUser.role) && parent.contractorObj ? parent.contractorObj : null;
    // Manager
    if ([Roles.manager].includes(currentUser.role)) {
      if (isRoomTicket(parent)) {
        const contractor = contractors.find((it) => it.guid === parent.contractor);
        subcontractor = { guid: parent.contractor, name: contractor?.name };
      } else {
        subcontractor = parent.contractorObj ?? (this.selectedContractor() ? { guid: this.selectedContractor().guid, name: this.selectedContractor().name } : null);
      }
    }
    const images: string[] = [];
    const videos: string[] = [];
    if (this.videoUrl) {
      videos.push(this.videoUrl);
    }
    if (isRoomTicket(parent)) {
      if (parent.videos?.length > 0) {
        videos.push(...parent.videos);
      }
      if (parent.images?.length > 0) {
        images.push(...parent.images);
      }
    }

    const ticket = {
      guid: item.guid,
      name: item.name,
      plainName: item.name,
      hours: item.hours,
      description: item.text,
      status: TicketStatus.created,
      history: [],
      images,
      videos,
      room,
      deadline: this.getDeadline(parent.deadline),
      users,
      usernames,
      subcontractor,
    };
    this.selectedTicket.set(ticket);
    this.hasSelectedTicket.emit(true);
  }

  emptySelectedTicket() {
    this.selectedTicket.set(null);
    this.hasSelectedTicket.emit(false);
  }

  onMic(text: string) {
    this.description = this.description ? `${this.description} ${text}` : text;
  }

  onReference(reference: StorageReference) {
    if (reference) {
      this.aiService.transcript(reference.fullPath).pipe(
        take(1),
      ).subscribe({
        next: async (data) => {
          this.description = data.data;
          this.loadingService.hideLoading();
          this.videoUrl = await getDownloadURL(reference);
        }, error: (error) => {
          console.error(error);
          this.loadingService.hideLoading();
          Dialog.alert({ message: this.translate.instant('tickets.ai.error') });
        },
      });
    } else {
      this.loadingService.hideLoading();
    }
  }

  doTasks(text: string, areas: SiteArea[], users: User[], contractors: SubItem[], canSelectSubcontractor: boolean) {
    this.loading.set(true);
    const currentUser = this.usersService.currentUserS();
    this.aiService.doTasks(text, this.siteS().guid).pipe(
      take(1),
    ).subscribe({
      next: (res: AiTask[]) => {
        this.loading.set(false);
        this.found.set(res.map((it) => {
          const room = this.getRoom(areas, it);
          const user = it.user ? users?.find((u) => u.displayName?.toLowerCase().includes(it.user.toLowerCase())) : null;
          const contractor = it.contractor && ([Roles.manager].includes(currentUser.role) || canSelectSubcontractor) ?
            contractors.find((c) => c.name.toLowerCase().includes(it.contractor.toLocaleLowerCase())) : null;
          return {
            guid: it.order,
            name: it.name,
            info: getRoomNameByRoom(room),
            text: it.description,
            item: Object.assign({}, it, { roomObj: room, userObj: user, contractorObj: contractor }) as ExtendedAiTask,
            hours: parseInt(it.duration),
            site: this.siteS().name,
            date: this.getDeadline(it.deadline),
            valid: this.selectedContractor() != null || this.contractorsService.contractorS() != null || contractor != null,
            video: this.videoUrl ?? null,
          };
        }));
        this.updateTicketValidation();
        this.step.update((val) => ++val);
      }, error: (error) => {
        console.error(error);
        this.loading.set(false);
        Dialog.alert({ message: this.translate.instant('tickets.ai.error') });
      },
    });
  }

  onTicketSave(tickets: RoomTicket[]) {
    const current = this.selectedTicket();
    this.found.update((val) => {
      const index = val.findIndex((it) => it.guid === current.guid);
      const edited = val[index];
      const items = this.getCarouselItems(tickets, edited.item);
      val.splice(index, 1, ...items);
      return val;
    });
    this.updateTicketValidation();
    this.selectedTicket.set(null);
  }

  onSite(site: Site) {
    this.siteS.set(site);
  }

  selectContractor(items: SubItem[]) {
    const clearButton = this.selectedContractor() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Contractor>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedContractor.set(null);
          } else {
            const contractor = data.data;
            const siteGuid = this.siteS().guid;
            if (contractor.sites != null && !contractor.sites.includes(siteGuid)) {
              this.contractorsService.updateSite(contractor.guid, siteGuid)
                .pipe(take(1))
                .subscribe(() => this.selectedContractor.set(contractor));
              return;
            }
            this.selectedContractor.set(contractor);
          }
        }
      });
    });
  }

  createTasks() {
    this.areaService.getAreaRooms(of(this.siteS())).pipe(
      take(1),
    ).subscribe((rooms) => {
      const tickets = this.found().map((it) => {
        if (isRoomTicket(it.item)) {
          return it.item;
        } else {
          const room = it.item.roomObj ?? rooms.find((r) => r.id === defaultRoom);
          return this.getRoomTicket(it.item, room);
        }
      });
      this.saved.emit(tickets);
      this.start();
    });
  }

  more() {
    this.description = '';
    this.found.set([]);
    this.step.set(1);
  }

  end() {
    this.closed.emit();
  }
}
