import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { documentId, where } from '@angular/fire/firestore';
import { Subscription } from '@invertase/firestore-stripe-payments';
import { FirestoreItem } from '@scandium-oy/ngx-scandium';
import { isAfter, startOfToday } from 'date-fns';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IService } from '../models/service.interface';
import { Site } from '../models/site.model';
import { User, UserReadNews } from '../models/user.model';
import { firestoreInLimit } from '../utility/constants';
import { Roles } from '../utility/role';
import { getDuration, isTimestamp } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'users';
const url = `${environment.backendUrl}/api/users`;

@Injectable({
  providedIn: 'root',
})
export class UsersService implements IService<User> {
  private users$ = new BehaviorSubject<User[]>([]);
  private usersEnabled$ = this.users$.pipe(
    filter((users) => users?.length > 0),
    map((users) => users.filter((u) => !u.disabled)),
  );

  private currentUser$ = new BehaviorSubject<User>(null);
  currentUserS = signal<User>(null);

  isSupervisor = computed(() => {
    const currentUser = this.currentUserS();
    if (currentUser == null) {
      return null;
    }
    return [Roles.admin, Roles.superAdmin].includes(currentUser.role) || currentUser.supervisor;
  });

  role = computed(() => {
    if (this.currentUserS()?.supervisor) {
      return Roles.supervisor;
    }
    return this.currentUserS()?.role;
  });

  role$ = this.currentUser$.pipe(
    filter((user) => user != null),
    map((user) => {
      if (user.supervisor) {
        return Roles.supervisor;
      }
      return user.role;
    }),
    shareReplay(1),
  );

  isLicenseExpired$ = this.getCurrentUser().pipe(
    filter((user) => user != null),
    map((user) => {
      if (user.activeEnds != null) {
        let date: Date;
        if (isTimestamp(user.activeEnds)) {
          date = user.activeEnds.toDate();
        } else {
          date = user.activeEnds;
        }
        return isAfter(startOfToday(), date);
      } else {
        return false;
      }
    }),
  );

  constructor(
    private firestore: FirestoreService,
    private http: HttpClient,
  ) { }

  async save(user: User) {
    return this.firestore.saveAs(itemCollection, user.guid, user);
  }

  async update(user: User) {
    user.updater = this.currentUserS()?.guid ?? null;
    return this.firestore.update(itemCollection, user);
  }

  async updateCreatingTime(userGuid: string, creationTime: string) {
    return this.firestore.updateOnly(itemCollection, userGuid, { creationTime });
  }

  async updateLang(userGuid: string, lang: string) {
    return this.firestore.updateOnly(itemCollection, userGuid, { lang });
  }

  async updateTheme(userGuid: string, theme: string) {
    return this.firestore.updateOnly(itemCollection, userGuid, { theme });
  }

  async updateReadNews(userGuid: string, readNews: UserReadNews[]) {
    return this.firestore.updateOnly(itemCollection, userGuid, { readNews });
  }

  async updateNotificationToken(userGuid: string, notificationTokens: string[]) {
    return this.firestore.updateOnly(itemCollection, userGuid, { notificationTokens });
  }

  async updateContractors(userGuid: string, contractors: string[]) {
    return this.firestore.updateOnly(itemCollection, userGuid, { contractors });
  }

  async updateWorksites(userGuid: string, worksites: string[]) {
    return this.firestore.updateOnly(itemCollection, userGuid, { worksites });
  }

  async enable(userGuid: string) {
    if (userGuid) {
      return this.firestore.updateOnly(itemCollection, userGuid, { disabled: false });
    }
  }

  async saveCheckoutSession(item: { guid?: string; price: string; success_url: string; cancel_url: string }, user: string) {
    return this.firestore.save(`${itemCollection}/${user}/checkout_sessions`, item);
  }

  invite(user: User) {
    return this.http.post<{ result: boolean; user: string }>(`${url}`, user);
  }

  inviteNonAuth(user: User) {
    return this.http.post<{ result: boolean; user: string }>(`${url}/invite`, user);
  }

  get(guid: string): Observable<User> {
    return this.users$.asObservable().pipe(
      map((users) => users.find((u) => u.guid === guid)),
    );
  }

  getSync(guid: string): User {
    return this.users$.getValue()?.find((u) => u.guid === guid);
  }

  getByUsername(name: string): User {
    return this.users$.getValue()?.find((u) => u.displayName === name);
  }

  getList(isWorking = false, options?: { site?: string; role?: string; contractor?: string }): Observable<User[]> {
    return this.usersEnabled$.pipe(
      map((users) => users.filter((u) => options?.site ? u.worksites?.includes(options.site) : true)),
      map((users) => users.filter((u) => options?.contractor ? u.contractors?.includes(options.contractor) : true)),
      map((users) => users.filter((u) => options?.role ? u.role === options.role : true)),
      map((users) => isWorking ? users.filter((u) => u.role?.length > 0 && u.role !== Roles.manager && !u.inactive) : users),
    );
  }

  getByGuids(guids: string[]): Observable<User[]> {
    return this.users$.asObservable().pipe(
      map((users) => users.filter((user) => guids.includes(user.guid))),
    );
  }

  getFromDb(guid: string): Observable<User> {
    return this.firestore.get<User>(itemCollection, guid);
  }

  getListFromDb(options?: { site?: string; role?: string; contractor?: string; guids?: string[] }): Observable<User[]> {
    const queryConstraints = [];
    if (options?.guids) {
      let ids = [...options.guids];
      if (ids?.length === 0) {
        return of([]);
      }
      const batches: Observable<User[]>[] = [];

      while (ids.length) {
        const batch = ids.splice(0, firestoreInLimit);
        batches.push(this.firestore
          .getList<User>(itemCollection, undefined, [where(documentId(), 'in', [...batch])],
          ));
      }

      return combineLatest(batches).pipe(
        map((results) => results.flat()),
      );
    }
    if (options?.site) {
      queryConstraints.push(where('worksites', 'array-contains', options.site));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractors', 'array-contains', options.contractor));
    }
    if (options?.role) {
      queryConstraints.push(where('role', '==', options.role));
    }
    return this.firestore.getList<User>(itemCollection, undefined, queryConstraints);
  }

  getMyList(suburl?: string): Observable<User[]> {
    if (environment.backendUrl) {
      const fullUrl = `${url}${suburl ? '/' + suburl : ''}`;
      return this.http.get<User[]>(fullUrl);
    } else {
      return of([]);
    }
  }

  getListOnce(options?: { site?: string; role?: string; contractor?: string }): Observable<User[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('worksites', 'array-contains', options.site));
    }
    if (options?.role) {
      queryConstraints.push(where('role', '==', options.role));
    }
    return this.firestore.getListOnce<User>(itemCollection, undefined, queryConstraints).pipe(
      map((users) => options.contractor ? users.filter((u) => u.contractors?.includes(options.contractor)) : users),
    );
  }

  getUserPayments(user: string) {
    return this.firestore.getList(`${itemCollection}/${user}/payments`);
  }

  getUserSubscriptions(user: string, options?: { worker: string }) {
    const queryConstraints = [where('status', 'not-in', ['canceled'])];
    if (options?.worker) {
      queryConstraints.push(where('metadata.guid', '==', options.worker));
    }
    return this.firestore.getList<Subscription & FirestoreItem>(`${itemCollection}/${user}/subscriptions`, undefined, queryConstraints);
  }

  getCheckoutSession(user: string, checkoutSessionId: string) {
    return this.firestore.get<{ error: { message: string }; url: string; guid?: string }>(itemCollection, `${user}/checkout_sessions/${checkoutSessionId}`);
  }

  setCurrentUser(user: User) {
    this.currentUserS.set(user);
    this.currentUser$.next(user);
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser$.asObservable().pipe(
      shareReplay(1),
    );
  }

  isSitelead(site: Site, userGuid?: string) {
    const uguid = userGuid ?? this.currentUserS()?.guid;
    return site.sitelead?.some((it) => it.guid === uguid);
  }

  /**
   * Sets local cache users
   * @param users
   */
  setUsers(users: User[]) {
    console.info(getDuration(), 'Setting users', users?.length);
    this.users$.next(users);
  }
}
